import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useRef } from "react";
import ContactComponent from "../components/ContactComponent";
import HeroComponent from "../components/HeroComponent";
import Layout from "../components/Layout";
import useHover from "../hooks/useHover";

const MotionText = motion(Text);

const CareersPage = ({ location }) => {
  const [heroTextRef, isHeroTextHovered] = useHover();
  const scrollHere = useRef(null);

  return (
    <Layout location={location}>
      <Box
        as="section"
        position="relative"
        flex="1"
        height="100%"
        background="#333333"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        minH="85vh"
        maxH="672px"
      >
        <Box
          position="relative"
          zIndex="5"
          px={{ base: "24px", md: "6rem" }}
          textAlign="center"
        >
          <Box
            display="flex"
            color="#FFFFFF"
            fontSize={{ base: "64px", md: "96px", lg: "120px" }}
          >
            <MotionText
              ref={heroTextRef}
              onClick={() => {
                scrollHere.current.scrollIntoView({ behavior: "smooth" });
                window.scrollTo({
                  top: 600,
                  behavior: "smooth",
                });
              }}
              animate={{
                opacity: 1,
                transition: { duration: 1.2, delay: 0 },
              }}
              initial={{ opacity: 0 }}
              whileHover={{
                y: -12,
                transition: {
                  ease: "easeInOut",
                  duration: 0.6,
                  repeat: Infinity,
                  repeatType: "mirror",
                },
              }}
              style={{
                color: "#C3F9E0",
                width: "100%",
                position: "relative",
                fontFamily: "Antonio",
                cursor: "pointer",
              }}
            >
              Careers
            </MotionText>
          </Box>
        </Box>
        <Box
          position="relative"
          zIndex="5"
          display="flex"
          justifyContent="center"
          textAlign="center"
          mb="12"
          fontSize={{ base: "20px", md: "24px", lg: "32px" }}
        >
          <motion.span
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 1.2, delay: 0.6, ease: "easeInOut" },
            }}
            initial={{ opacity: 0, y: -80 }}
            style={{
              position: "relative",
              fontFamily: "Montserrat",

              lineHeight: "40px",
              color: "#ffffff",
              fontWeight: 600,
            }}
          >
            Want to join our team?
            <br />
            Checkout the available positions below
          </motion.span>
        </Box>
        <Box
          position="relative"
          zIndex="5"
          display="flex"
          justifyContent="center"
          textAlign="center"
        >
          <Button
            colorScheme="primary"
            borderRadius="4px"
            boxShadow="none"
            p="24px"
            color="secondary.500"
            fontWeight="bold"
            onClick={() => {
              scrollHere.current.scrollIntoView({ behavior: "smooth" });
              window.scrollTo({
                top: 600,
                behavior: "smooth",
              });
            }}
          >
            See careers
          </Button>
        </Box>
        <Box>
          <Box
            position="absolute"
            zIndex="4"
            width="100%"
            height="100%"
            top="0"
            left="0"
            right="0"
            bottom="0"
            opacity="0.5"
            backgroundColor={isHeroTextHovered ? "secondary.700" : "#000000"}
            backgroundBlendMode={isHeroTextHovered ? "multiply" : "initial"}
            transition="2s all ease-in-out"
          />
          <HeroComponent>
            <Box
              height="100%"
              backgroundImage="url('/img/ae-hiring-hero.jpg')"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
            />
          </HeroComponent>
        </Box>
      </Box>

      <Box as="section" ref={scrollHere} className="wrapper-xl" mt={8} mb={12}>
        <Box
          my="24"
          minH="30vh"
          display="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            as="h3"
            color="secondary.500"
            letterSpacing="-3px"
            fontSize={{ base: "20px", md: "28px", lg: "36px" }}
            lineHeight={{ base: "24px", md: "32px", lg: "42px" }}
            textAlign="center"
            fontWeight="medium"
          >
            No available openings
          </Text>
          <Text
            as="p"
            color="secondary.500"
            fontSize={{ base: "20px" }}
            lineHeight={{ base: "28px" }}
            textAlign="center"
            mt="2"
          >
            Look out! We will be posting more in the future.
          </Text>
          {/* <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="4"
        >
          <Text
            as="h3"
            color="secondary.500"
            letterSpacing="-3px"
            fontSize={{ base: "20px", md: "28px", lg: "36px" }}
            lineHeight={{ base: "24px", md: "32px", lg: "42px" }}
          >
            Business Development Manager
          </Text>
          <Button
            onClick={() =>
              window.open(
                "mailto:ileung@aevias.com?subject=Business Development Manager - [Your Name]",
                "_blank"
              )
            }
            variant="solid"
            colorScheme="secondary"
          >
            Email Us
          </Button>
        </Box>
        <Box
          backgroundColor="secondary.500"
          position="relative"
          borderRadius="16px"
          p={12}
          color="#FFF"
        >
          <Text as="p">
            Join our dynamic and innovative event planning team as a Business
            Development Manager. We are seeking a motivated professional with a
            strong sales orientation and a track record of exceeding targets. In
            this role, you will identify and cultivate new business
            opportunities, build client relationships, and successfully close
            sales.
          </Text>
          <br />
          <Accordion defaultIndex={[0, 1, 2]} allowMultiple>
            <AccordionItem border="none" mb={4}>
              <Text as="h3" fontWeight="medium">
                <AccordionButton px={0}>
                  <Box flex="1" textAlign="left" fontWeight="medium">
                    Responsibilities:
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Text>
              <AccordionPanel
                fontFamily="'Montserrat'"
                background="primary.500"
                py={4}
                color="#333333"
                borderRadius={4}
                mt={2}
              >
                <UnorderedList>
                  <ListItem>
                    Identify and develop business opportunities through
                    networking and strategic meetings.
                  </ListItem>
                  <ListItem>
                    Cultivate relationships with potential clients, understand
                    their needs, and present event solutions.
                  </ListItem>
                  <ListItem>
                    Present services through direct communication, meetings,
                    presentations, and emails.
                  </ListItem>
                  <ListItem>
                    Maintain an accurate business opportunity pipeline and
                    forecast.
                  </ListItem>
                  <ListItem>
                    Negotiate contracts with potential clients, collaborating
                    with the management team.
                  </ListItem>
                  <ListItem>
                    Stay informed about industry trends, competitors, and market
                    conditions.
                  </ListItem>
                  <ListItem>
                    Collaborate with the team to surpass financial targets
                    through acquiring new clients and generating business.
                  </ListItem>
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem border="none" mt={2}>
              <Text as="h3">
                <AccordionButton px={0}>
                  <Box fontWeight="medium" flex="1" textAlign="left">
                    Qualifications:
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Text>
              <AccordionPanel
                fontFamily="'Montserrat'"
                background="primary.500"
                py={4}
                color="#333333"
                borderRadius={4}
                mt={2}
              >
                <ListItem>
                  Proven experience as a Business Development Manager or in
                  relevant sales roles.
                </ListItem>
                <ListItem>
                  Preferably experienced in the meetings and events industry but
                  not mandatory.
                </ListItem>
                <ListItem>
                  Exceptional communication and presentation skills.
                </ListItem>
                <ListItem>
                  Strong negotiation and networking abilities.
                </ListItem>
                <ListItem>
                  Proficient in MS Office Suite and CRM software.
                </ListItem>
                <ListItem>Willingness to travel as required.</ListItem>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem border="none" mt={2}>
              <Text as="h3">
                <AccordionButton px={0}>
                  <Box fontWeight="medium" flex="1" textAlign="left">
                    Benefits:
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </Text>
              <AccordionPanel
                fontFamily="'Montserrat'"
                background="primary.500"
                py={4}
                color="#333333"
                borderRadius={4}
                mt={2}
              >
                <ListItem>
                  Competitive salary with commission-based remuneration.
                </ListItem>
                <ListItem>Join a dynamic and innovative team.</ListItem>
                <ListItem>Flexible work hours.</ListItem>
                <ListItem>
                  Potential for a permanent role based on performance.
                </ListItem>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <br />
          <Text as="p">
            <strong>How to Apply:</strong>
            <br />
            Qualified, interested candidates, please send your CV and cover
            letter to ILeung@aevias.com with &#39;Business Development Manager -
            [Your Name]&#39; in the subject line. We appreciate your
            application; however, only shortlisted candidates will be contacted.
            No phone calls, please.
            <br />
            At Aevias, we embrace diversity and are dedicated to fostering an
            inclusive environment for all employees.
          </Text> */}
        </Box>
        {/* </Box> */}
      </Box>

      <ContactComponent />
    </Layout>
  );
};

export default CareersPage;
